<template>
  <div class="my-header">
    <!-- tiao -->
    <div class="my-header-list-box" ref="headerBox">
      <div class="my-header-list" @mousedown="drag($event)" ref="headerBar">
        <div
          class="list-label"
          v-for="(item, index) in radioGroupBtn"
          :key="item.id"
          :class="{ labelBorder: index === currentIndex }"
          @click="clickItem(item, index)"
        >
          {{ item.accountName }}
        </div>
      </div>
    </div>
    <BaseForm label-width="80px" :cols="formField" :form="query" class="header-form">
      <template #shelvesSlot="{ scoped: { prop, placeholder } }">
        <el-select v-model="query[prop]" :placeholder="placeholder" clearable @change="toQuery">
          <el-option :value="1" label="已上架"></el-option>
          <el-option :value="2" label="已下架"></el-option>
        </el-select>
      </template>
      <template #operation>
        <el-button type="primary" class="el-icon-search" @click="toQuery" size="mini"></el-button>
      </template>
    </BaseForm>
  </div>
</template>

<script>
import { commonEheaderMixin } from '@/mixins'
import { childList } from '../../api.js'
import { mapGetters } from 'vuex'
export default {
  mixins: [commonEheaderMixin],
  components: {},
  props: {
    formField: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      currentIndex: 0,
      radioGroupBtn: [
        {
          accountName: '全部',
          id: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['isMain'])
  },
  methods: {
    clickItem(item, index) {
      this.currentIndex = index
      console.log('this.currentIndex', this.currentIndex)
      if (item.accountName === '全部') {
        delete this.query.ownerId
        this.$set(this.query, 'childrenIdList', [1])
      } else {
        this.$delete(this.query, 'childrenIdList')
        this.$set(this.query, 'ownerId', item.id)
      }
      this.toQuery()
      this.$emit('changeChildHandel')
    },
    // 移动事件
    drag(e) {
      // 获取点击时的坐标
      let pos = this.getPos(e)
      // 获得最开始点击的x坐标
      let startX = pos.x
      const style = this.$refs['headerBar'].style
      let marginLeft = parseInt(style['margin-left'])
      if (isNaN(marginLeft)) {
        marginLeft = 0
      }
      // 设置移动的距离
      let boxWidth = this.$refs['headerBox'].clientWidth
      if (this.radioGroupBtn.length * 140 > boxWidth) {
        document.onmousemove = (e) => {
          let pos = this.getPos(e)
          let nowX = pos.x
          let moveX = nowX - startX
          style['margin-left'] = moveX + marginLeft + 'px'
          if (moveX + marginLeft >= 0) {
            style['margin-left'] = '0px'
            //   document.onmousemove = null
          }
          let minleft = boxWidth - this.radioGroupBtn.length * 140
          if (moveX + marginLeft <= minleft) {
            style['margin-left'] = minleft + 'px'
          }
        }
      }
      document.onmouseup = function () {
        /*鼠标放开清除事件*/
        document.onmousemove = null
        document.onmouseup = null
      }
    },
    // 获取鼠标坐标
    getPos(ev) {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft
      return { x: ev.clientX + scrollLeft, y: ev.clientY + scrollTop }
    },
    // 获取子账号列表
    async getChildList() {
      const { detail } = await childList()
      this.radioGroupBtn = this.radioGroupBtn.concat(detail)
    }
  },
  created() {
    if (this.isMain) {
      this.getChildList()
    }
  }
}
</script>

<style scoped lang="scss">
.my-header {
  .my-header-list-box {
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    .my-header-list {
      display: flex;
      .list-label {
        font-size: 14px;
        height: 40px;
        width: 140px;
        text-align: center;
        line-height: 40px;
        flex-grow: 0;
        flex-shrink: 0;
        color: #333;
        border: 1px solid #dcdee0;
        background-color: #f7f8fa;
        cursor: pointer;
        user-select: none;
      }
      .list-label:first-child {
        border-radius: 4px 0 0 4px;
      }
      .list-label:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  }
  .labelBorder {
    border-bottom: 3px solid $color-primary !important;
    color: $color-primary;
  }
  .header-form {
    background-color: #f7f8fa;
  }
}
</style>
